const { Row, List } = require('reactstrap');
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const FeatureSignUpBasic = () => {
  const [t, ready] = useTranslation(['auth']);
  if (!ready) return null;
  return (
    <Row className="justify-content-center">
      <h1 style={{ fontSize: '33.3px' }} className="text-white">
        {t('auth:signUpBasicHeading')}
      </h1>
      <List className="mt--4 text-white" type="unstyled">
        <li className="mt-4 d-flex">
          <FontAwesomeIcon
            className="icon-container mt-1"
            icon={faCircleCheck}
            style={{ color: '#2db577' }}
          />
          <span className="ml-1 text-container">
            {t('auth:signUpBasicFeatuerFree')}
          </span>
        </li>
        <li className="mt-1 d-flex">
          <FontAwesomeIcon
            className="icon-container mt-1"
            icon={faCircleCheck}
            style={{ color: '#2db577' }}
          />
          <span className="ml-1 text-container">
            {t('auth:signUpBasicFlowShareWindows')}
          </span>
        </li>
        <li className="mt-1 d-flex">
          <FontAwesomeIcon
            className="icon-container mt-1"
            icon={faCircleCheck}
            style={{ color: '#2db577' }}
          />
          <span className="ml-1 text-container">
            {t('auth:signUpBasicFeatureUnlimitedGuides')}
          </span>
        </li>
      </List>
    </Row>
  );
};

export default FeatureSignUpBasic;
