import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { setUser } from 'redux/actions/users.actions';
import { userService } from 'services/user.service';
import { setJustLoggedIn } from 'redux/actions/misc.actions';
import { setOrganization } from 'redux/actions/organization.actions';
import { setOrganizationMembership } from 'redux/actions/organizationMembership.actions';
import Routes from 'routes/routes';
import { Buffer } from 'buffer';
import { setFlowsCount } from 'redux/actions/flows.actions';
import { openNotification } from 'utlis/notification';
import NOTIFICATION_TYPES from 'constants/notificationType';
import verificationSuccessffulIcon from '../../assets/img/icons/varification_sucessful.svg';
import verificationErrorIcon from '../../assets/img/icons/verification_error.svg';
import { Link } from 'react-router-dom';
import SignUpNavbar from './SignUpNavbar';

const EmailVerify = () => {
  const { t, ready } = useTranslation(['auth']);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        localStorage.clear();
        setLoading(true);
        const response = await userService.verifyEmail(params.token);

        localStorage.setItem('POR', Buffer.from(response).toString('base64'));

        const userProfile = await userService.getUserProfile();

        dispatch(setUser(userProfile.user));

        dispatch(setOrganization(userProfile.organization));

        dispatch(setOrganizationMembership(userProfile.organizationMembership));

        dispatch(setFlowsCount(userProfile.flowsCount));

        dispatch(setJustLoggedIn(true));

        navigate(Routes.app.dashboard);
      } catch (e) {
        localStorage.clear();
        setIsError(true);
        setLoading(false);
        openNotification(e.error, NOTIFICATION_TYPES.ERROR);
      }
    };

    verifyEmail();
  }, []);

  if (!ready) return null;
  return (
    <>
      <SignUpNavbar />
      <Col lg="5" md="7">
        <Card className="bg-secondr shadow border-0 align-items-center justify-content-center">
          {!loading && !isError && (
            <CardBody>
              <img
                src={verificationSuccessffulIcon}
                alt="icon"
                height={'50px'}
                width={'50px'}
                className="mb-3 ml-7"
              />
              <h2 className="ml-1"> {t('auth:verifyEmailSuccessHeader')} </h2>
              <Row className="justify-content-center  mb-2 ml-3 mt-4">
                <span style={{ fontSize: '1rem' }}>
                  {t('auth:verifyEmailSuccessText')}
                </span>
              </Row>
            </CardBody>
          )}

          {!loading && isError && (
            <CardBody>
              <img
                src={verificationErrorIcon}
                alt="icon"
                height={'50px'}
                width={'50px'}
                className="mb-3 ml-7"
              />
              <h2 className="ml-1"> {'Something went wrong'} </h2>
              <Row className="justify-content-center mb-2 ml-3 mt-4">
                <Link to={Routes.auth.login}>
                  <Button color="primary">
                    {t('auth:verifyEmailBackToSignInButton')}
                  </Button>
                </Link>
              </Row>
            </CardBody>
          )}

          {loading && (
            <CardBody>
              <spain>
                {t('auth:verifyEmailLoadingText')}
                {loading && (
                  <span className="spinner-border spinner-border-sm" />
                )}
              </spain>
            </CardBody>
          )}
        </Card>
      </Col>
    </>
  );
};

export default EmailVerify;
