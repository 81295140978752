import Header from 'components/Header.js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import dashboardVideo from '../assets/videos/customer-portal-welcome-video-1.mp4';
import routes from '../routes/routes';
import dashboardDownloadIcon from '../assets/img/icons/dashboard_download.svg';
import dashboarInviteIcon from '../assets/img/icons/dashboard_invite.svg';
import dashboardSupportIcon from '../assets/img/icons/dashboard_support.svg';
import DashboardWidget from './DashboardWidget';
import ResponsiveVideoCard from './VideoComponent';
import AccountSetupModal from './signUp/AccountSetupModal';

const Index = () => {
  const { t, ready } = useTranslation(['index', 'global']);
  const misc = useSelector((state) => state.misc);
  const organization = useSelector((state) => state.organization);

  useEffect(() => {
    document.body.style.backgroundColor = '#eef8ff';
  }, []);

  if (!ready) return null;

  return (
    <>
      <Header activeLocation={t('index:dashboard')} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col
            className="d-flex flex-row flex-below-1228"
            style={{ gap: '1rem' }}
          >
            <DashboardWidget
              header={t('index:downloadFlowShareWidgetHeader')}
              text={t('index:downloadFlowShareText')}
              link={t('index:downloadLink')}
              buttonText={t('index:downloadFlowShareButtonText')}
              iconLink={dashboardDownloadIcon}
              primary={true}
            />

            <DashboardWidget
              header={t('inviteUserWidgetHeader')}
              text={t('index:inviteUserWidgetText')}
              link={routes.app.manageMembers}
              buttonText={t('global:manageMembers')}
              iconLink={dashboarInviteIcon}
              primary={false}
            />

            <DashboardWidget
              header={t('index:getSupportWidgetHeader')}
              text={t('index:getSupportText')}
              link={t('global:suportLink')}
              buttonText={t('index:getSupportButtonText')}
              iconLink={dashboardSupportIcon}
              primary={false}
            />
          </Col>
        </Row>

        <ResponsiveVideoCard
          headerText={t('index:cardHeaderText')}
          videoSrc={dashboardVideo}
        />
      </Container>
      {misc.justLoggedIn && !organization.accountSetup && <AccountSetupModal />}
    </>
  );
};

export default Index;
