import { Spinner } from 'reactstrap';

const FlowViewerLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
      }}
    >
      <Spinner color="primary">{null}</Spinner>
    </div>
  );
};

export default FlowViewerLoader;
