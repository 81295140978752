import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { userService } from 'services/user.service';

import NOTIFICATION_TYPES from '../constants/notificationType';
import Routes from '../routes/routes';
import { openNotification } from '../utlis/notification';
import AuthNavbar from './AuthNavbar';

const PasswordForgot = () => {
  const navigate = useNavigate();
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);

  const [email, setEmail] = useState('');

  const [readyToSend, setReadyToSend] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email !== '') {
      setReadyToSend(false);
    } else {
      setReadyToSend(true);
    }
  }, [email]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await userService.forgotPassword(email);
      openNotification(
        t('notifications:passwordResetSuccess'),
        NOTIFICATION_TYPES.SUCCESS
      );

      navigate(Routes.auth.login);
    } catch (e) {
      setLoading(false);
      setReadyToSend(false);
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
    }
  };

  return (
    <>
      <AuthNavbar />
      <Container>
        <div className="header-body text-center mb-6">
          <Row className="justify-content-center">
            <Col lg="5" md="6">
              <h1 className="text-white">{t('auth:authTitle')}</h1>
            </Col>
          </Row>
        </div>
      </Container>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader>
            <h4>{t('auth:passwordForgotHeading')}</h4>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>

                  <Input
                    placeholder={t('global:email')}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div className="text-center">
                <Button
                  disabled={readyToSend}
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  {t('global:reset')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link style={{ color: 'white' }} to="/auth/login/">
              <small>{t('global:signIn')}</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link as={'li'} style={{ color: 'white' }} to={`/auth/sign-up`}>
              <small>{t('auth:signUpText')} </small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PasswordForgot;
