import { useTranslation } from 'react-i18next';
import { Button, Card, Container, FormGroup } from 'reactstrap';

const CenteredMessage = () => {
  const [t, ready] = useTranslation(['auth', 'index']);

  if (!ready) return null;
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
    >
      <Card
        className="d-flex justify-content-center align-items-center"
        style={{ maxWidth: '28rem', padding: '1rem' }}
      >
        <h2 className="weight-700 text-center">{t('auth:authorizeHeader')}</h2>

        <p>{t('auth:authorizeText')}</p>
        <FormGroup>
          <Button color="primary" href={t('index:downloadLink')}>
            {t('auth:authorizeButtonText')}
          </Button>
        </FormGroup>
      </Card>
    </Container>
  );
};

export default CenteredMessage;
