import NOTIFICATION_TYPES from 'constants/notificationType';
import ReactConfetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setJustLoggedIn } from 'redux/actions/misc.actions';
import { setOrganization } from 'redux/actions/organization.actions';
import { organizationService } from 'services/organization.service';
import { openNotification } from 'utlis/notification';
const { useState, useEffect } = require('react');
const {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Input,
  Label,
  Progress,
} = require('reactstrap');

const AccountSetupModal = () => {
  const { t, ready } = useTranslation([
    'index',
    'global',
    'auth',
    'notifications',
  ]);

  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [stepUseCaseContent, setStepUseCaseContent] = useState(true); //used to show the content for the selection of use case
  const [useCaseContent, setUseCaseContent] = useState('');
  const [stepWorkSpaceNameContent, setStepWorkSpaceNameContent] =
    useState(false); //used to show the content for worksapce
  const [organizationName, setOrganizationName] = useState('');
  const [phone, setPhone] = useState('');
  // to change class of the button
  const [selectedButton, setSelectedButton] = useState(null);
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(1);
  const [stepDownloadFlowShareContent, setStepDownloadFlowShareContent] =
    useState(false);

  useEffect(() => {
    if (useCaseContent !== '') {
      setReadyToSend(true);
    }
  }, [useCaseContent]);

  useEffect(() => {
    if (organizationName !== '') {
      setReadyToSend(true);
    }
  }, [organizationName]);

  if (!ready) return null;

  const handleUseCaseClick = async () => {
    try {
      setLoading(true);
      await organizationService.updateOrganization(organization.email, {
        useCase: useCaseContent,
      });

      setLoading(false);
      setStepUseCaseContent(false);
      setStepWorkSpaceNameContent(true);
      setReadyToSend(false);
      setProgressValue(2);
    } catch (error) {
      openNotification(error.error, NOTIFICATION_TYPES.ERROR);
    }
  };
  const handleOrganizationName = async () => {
    try {
      setLoading(true);
      await organizationService.updateOrganization(organization.email, {
        name: organizationName,
        phone: phone,
      });

      openNotification(
        t('notifications:accountSetupSuccess'),
        NOTIFICATION_TYPES.SUCCESS
      );
      setStepWorkSpaceNameContent(false);
      setStepDownloadFlowShareContent(true);
      setProgressValue(3);
    } catch (error) {
      openNotification(error.error, NOTIFICATION_TYPES.ERROR);
      setLoading(false);
    }
  };

  const handleDownloadFlowShareButtonClick = async () => {
    window.open(`${t('index:downloadLink')}`, '_blank');
    // handleFinish();
  };

  const handleFinish = async () => {
    dispatch(
      setOrganization({
        ...organization,
        name: organizationName,
        accountSetup: true,
      })
    );
    dispatch(setJustLoggedIn(false));
  };
  return (
    <>
      {stepDownloadFlowShareContent && (
        <ReactConfetti
          width={window.innerWidth * 0.6}
          height={window.innerHeight}
          gravity={0.35}
          initialVelocityX={2}
          initialVelocityY={2}
          numberOfPieces={300}
          opacity={1}
          wind={0}
          style={{
            position: 'fixed',
            top: 0,
            left: `${window.innerWidth * 0.22}px`,
            zIndex: 1100,
            pointerEvents: 'none',
          }}
          recycle={false}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        centered
        style={{ maxWidth: '47rem', padding: '1rem' }}
        backdropClassName="color-modal"
      >
        <Progress
          value={progressValue}
          max={3}
          barClassName="account-setup-progress-bar"
          className="account-setup-progress "
        />

        <ModalBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '1rem',
            maxWidth: '47rem',
            minHeight: '22rem',
          }}
        >
          {stepUseCaseContent && (
            <>
              <div
                style={{
                  paddingLeft: '4rem',
                  paddingRight: '4rem',
                }}
              >
                <h1 className="weight-700">{t('auth:accountSetupHeader')}</h1>
                <div className="mb-2">{t('auth:accountSetupText')}</div>

                <div className="button-flex-container">
                  <div className="button-row">
                    <Button
                      onClick={() => {
                        setUseCaseContent('ERP-Project');
                        setSelectedButton('erpProject');
                      }}
                      className={`account-setup-custom-button ${
                        selectedButton === 'erpProject' ? 'active' : ''
                      }`}
                    >
                      {`${t('auth:erpProject')}`}
                    </Button>
                    <Button
                      onClick={() => {
                        setUseCaseContent('IT-Projects');
                        setSelectedButton('itProjects');
                      }}
                      className={`account-setup-custom-button ${
                        selectedButton === 'itProjects' ? 'active' : ''
                      }`}
                    >
                      {`${t('auth:itProjects')}`}
                    </Button>
                  </div>
                  <div className="button-row">
                    <Button
                      onClick={() => {
                        setUseCaseContent('SupportOrTraining');
                        setSelectedButton('supportOrtraining');
                      }}
                      className={`account-setup-custom-button ${
                        selectedButton === 'supportOrtraining' ? 'active' : ''
                      }`}
                    >
                      {`${t('auth:supportOrTraining')}`}
                    </Button>
                    <Button
                      onClick={() => {
                        setUseCaseContent('HeyGen');
                        setSelectedButton('heyGen');
                      }}
                      className={`account-setup-custom-button ${
                        selectedButton === 'heyGen' ? 'active' : ''
                      }`}
                    >
                      {`${t('auth:heyGen')}`}
                    </Button>
                  </div>
                  <div className="button-row">
                    <Button
                      onClick={() => {
                        setUseCaseContent('Other');
                        setSelectedButton('other');
                      }}
                      className={`account-setup-custom-button ${
                        selectedButton === 'other' ? 'active' : ''
                      }`}
                    >
                      {`${t('auth:other')}`}
                    </Button>
                  </div>
                </div>
              </div>

              <Row>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 'auto',
                      gap: '19px',
                      cursor: 'pointer',
                    }}
                  >
                    <Button
                      color="primary"
                      className="btn-primary"
                      onClick={handleUseCaseClick}
                      disabled={!readyToSend}
                    >
                      {t('Next')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {stepWorkSpaceNameContent && (
            <>
              <div
                style={{
                  marginLeft: '2rem',
                  paddingTop: '1rem',
                }}
              >
                <h1 className="weight-700">
                  {t('auth:accountSetupStepTwoHeader')}
                </h1>
                <div className="mb-2">{t('auth:accountSetupStepTwoText')}</div>
                <Row
                  style={{
                    paddingRight: '19rem',
                  }}
                >
                  <Col className="col-12">
                    <FormGroup className="mb-3">
                      <Label> {t('auth:companyNameInputLabel')}</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder={`Acme CORP`}
                          type="text"
                          onChange={(e) => setOrganizationName(e.target.value)}
                          required
                          className="input-field"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col className="col-12">
                    <FormGroup className="mb-3">
                      <Label> {t('auth:phoneNumberInputLabel')}</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder={t('auth:phoneNumberPlaceHolder')}
                          type="text"
                          onChange={(e) => {
                            let value = e.target.value;
                            // Allow '+' only at the start and remove other non-numeric characters
                            if (value.startsWith('+')) {
                              value = '+' + value.slice(1).replace(/\D/g, '');
                            } else {
                              value = value.replace(/\D/g, '');
                            }
                            setPhone(value);
                          }}
                          value={phone}
                          className="input-field"
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 'auto',
                    gap: '19px',
                    alignItems: 'baseline',
                  }}
                >
                  <p
                    color="primary"
                    onClick={() => {
                      setStepWorkSpaceNameContent(false);
                      setStepDownloadFlowShareContent(true);
                      setProgressValue(3);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('global:skip')}
                  </p>
                  <Button
                    color="primary"
                    className="btn-primary"
                    onClick={handleOrganizationName}
                    disabled={organizationName === '' && phone === ''}
                  >
                    {t('global:next')}
                    {loading && (
                      <span className="spinner-border spinner-border-sm" />
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {stepDownloadFlowShareContent && (
            <>
              <div
                style={{
                  marginLeft: '4rem',
                  marginRight: '2rem',
                  paddingTop: '1rem',
                }}
              >
                <h2
                  style={{ marginRight: '3rem' }}
                  className="weight-700 text-center"
                >
                  {t('auth:accountSetupStepThreeHeader')}
                </h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 'auto',
                    gap: '19px',
                    marginRight: '3rem',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleDownloadFlowShareButtonClick();
                    }}
                    className={`account-setup-custom-button active justify-content-center`}
                    style={{
                      maxWidth: '15rem',
                      marginTop: '2rem',
                    }}
                  >
                    {t('auth:accountSetupDownloadButtonText')}
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    flexDirection: 'column',
                  }}
                  className="mt-3"
                >
                  <span> {t('auth:accountSetupInstructionOne')}</span>
                  <span> {t('auth:accountSetupInstructionTwo')}</span>
                  <span>{t('auth:accountSetupInstructionThree')}</span>
                  <span>{t('auth:accountSetupInstructionText')}</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 'auto',
                  gap: '19px',
                  cursor: 'pointer',
                }}
              >
                <Button
                  color="primary"
                  className="btn-primary"
                  onClick={handleFinish}
                >
                  {t('global:finish')}
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AccountSetupModal;
