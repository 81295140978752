import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  ModalBody,
} from 'reactstrap';

import NotificationType from '../constants/notificationType';
import ORGANIZATION_MEMBERSHIP_ROLE from '../constants/organizationMembershipRole';
import { memberService } from '../services/member.service';
import { openNotification } from '../utlis/notification';
import { validateName } from 'utlis/utils';

const InviteMemberModal = (props) => {
  const { t, ready } = useTranslation(['global', 'members', 'notifications']);

  const organization = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);
  const [readyToSend, setReadyToSend] = useState(false);
  const [role, setRole] = useState(ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) &&
      firstName !== '' &&
      lastName !== ''
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email, firstName, lastName]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateName(firstName) || !validateName(lastName)) {
      openNotification(t('notifications:validateName'), NotificationType.ERROR);
      return;
    }
    try {
      setLoading(true);
      const newMember = await memberService.inviteTeamMember(
        organization.id,
        email,
        role,
        firstName,
        lastName
      );

      openNotification(
        t('notifications:membersAddedSuccess'),
        NotificationType.SUCCESS
      );
      props.pushNewMember(newMember);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  return (
    <ModalBody>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <label className="form-control-label">{t('global:email')}</label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={t('global:email')}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              defaultValue={email}
              required
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label">
            {t('global:firstNameLabel')}
          </label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={t('global:firstNamePlaceHolderText')}
              type="text"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              defaultValue={firstName}
              required
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label">
            {t('global:lastNameLabel')}
          </label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={t('global:lastNamePlaceholderText')}
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              defaultValue={lastName}
              required
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-control-label">{t('global:Role')}</label>
          <InputGroup className="input-group-alternative">
            <Input
              type="select"
              onChange={(e) => {
                setRole(e.target.value);
              }}
              style={{
                minWidth: '100%',
                minHeight: '40px',
                boxShadow:
                  '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                border: '0',
                transition: 'box-shadow 0.15s ease',
                borderRadius: '5px',
              }}
              defaultValue={role}
              required
            >
              <option value={ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR}>
                {ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR.charAt(0).toUpperCase() +
                  ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR.slice(1)}
              </option>
              <option value={ORGANIZATION_MEMBERSHIP_ROLE.ADMIN}>
                {ORGANIZATION_MEMBERSHIP_ROLE.ADMIN.charAt(0).toUpperCase() +
                  ORGANIZATION_MEMBERSHIP_ROLE.ADMIN.slice(1)}
              </option>
              <option value={ORGANIZATION_MEMBERSHIP_ROLE.CHATTER}>
                {ORGANIZATION_MEMBERSHIP_ROLE.CHATTER.charAt(0).toUpperCase() +
                  ORGANIZATION_MEMBERSHIP_ROLE.CHATTER.slice(1)}
              </option>
            </Input>
          </InputGroup>
        </FormGroup>
        <div className="text-center float-left">
          <Button
            disabled={!readyToSend}
            className="my-4"
            type="submit"
            color={'primary'}
          >
            {t('members:invite')}
            {loading && <span className="spinner-border spinner-border-sm" />}
          </Button>
        </div>
      </Form>
    </ModalBody>
  );
};

export default InviteMemberModal;
