import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const AuthLayOut = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    if (mainContent.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <div className="auth-layout" style={{ backgroundColor: '#eef8ff' }}>
      <div className="main-content" ref={mainContent}>
        <div className="header py-7 py-lg-8 mt--4">
          <Container>
            <div className="header-body text-center mb-7"></div>
          </Container>
        </div>
        <Container style={{ marginTop: '-13rem' }} className=" pb-5">
          <Row className="justify-content-center">
            <Outlet />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AuthLayOut;
