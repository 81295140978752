import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';

const PostSignupMessage = () => {
  const { t, ready } = useTranslation(['auth']);
  if (!ready) return null;
  return (
    <Card className="bg-secondary shadow border-0 text-center">
      <CardBody>
        <p style={{ fontSize: '1.02rem' }}>
          {t('auth:postSignupMessageSubHeading')}
        </p>

        <p style={{ fontSize: '1.02rem' }}>{t('auth:postSignupMessageText')}</p>

        <p style={{ fontSize: '1.02rem' }}>
          {t('auth:postSignUpMessageFooter')}
        </p>
      </CardBody>
    </Card>
  );
};

export default PostSignupMessage;
