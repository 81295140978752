import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import Routes from 'routes/routes';
import { useLocation } from 'react-router-dom';
import SignUpNavbar from './SignUpNavbar';
import { Buffer } from 'buffer';
import useGoogleTagManager from 'components/hook/GoogleTagManager';
import { useTranslation } from 'react-i18next';

const AccountLinkedPostSSOSignup = () => {
  const [t, ready] = useTranslation(['auth', 'global']);

  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;

  if (token) {
    localStorage.setItem('POR', Buffer.from(token).toString('base64'));
  }
  useGoogleTagManager('GTM-KJ6CVXZ6');
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(Routes.app.dashboard);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  if (!ready) return null;
  return (
    <>
      <SignUpNavbar />
      <Card centered style={{ maxWidth: '28rem', padding: '1rem' }}>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '1rem',
          }}
        >
          <h2 className="weight-700 text-center">
            {t('auth:ssoSignupAccountLinkedHeader')}
          </h2>

          <p>{t('auth:ssoSignupAccountLinkedText')}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: 'auto',
              gap: '19px',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(Routes.app.dashboard);
            }}
            color={'primary'}
            className="text-underline"
          >
            {t('global:continue')}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AccountLinkedPostSSOSignup;
