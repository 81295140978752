import { Buffer } from 'buffer';

export function getAuthHeader(browserLanguage) {
  const token = localStorage.getItem('POR');

  if (token) {
    const buff = Buffer.from(token, 'base64');

    const str = buff.toString('ascii');
    return {
      Authorization: 'Bearer ' + str,
      'Content-Type': 'application/json',
      'x-custom-lang': browserLanguage,
    };
  } else {
    return {
      'Content-Type': 'application/json',
      'x-custom-lang': browserLanguage,
    };
  }
}
