const Routes = {
  auth: {
    login: '/auth/login',
    loginFlowShare: '/flowshare/login',
    loginFlowShareV2: '/flowshare/v2/login',
    passwordForgot: '/auth/password-forgot',
    passwordReset: '/auth/password-reset',
    signUp: '/auth/sign-up',
    verifyEmail: '/auth/verify-email',
    registrationSuccessfull: '/auth/registration-sent',
    ssoAccountLinked: '/auth/account-linked',
    microsoftAuthorizationCallBack: '/auth/microsoft/callback',
  },
  app: {
    dashboard: '/app',
    userProfile: '/app/user-profile',
    billing: '/app/billing',
    manageLicenses: '/app/manage-licenses',
    manageMembers: '/app/manage-members',
    manageFlows: '/app/manage-flows',
    managePlans: '/app/manage-plans',
    referral: '/app/referrals',
  },
};

export default Routes;
