import useGoogleTagManager from 'components/hook/GoogleTagManager';
import PostSignupMessage from './PostSignupMessaage';
import SignUpNavbar from './SignUpNavbar';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const RegistrationSuccessfull = () => {
  const [t, ready] = useTranslation(['auth']);
  if (!ready) return null;
  useGoogleTagManager('GTM-KJ6CVXZ6');
  return (
    <>
      <SignUpNavbar />
      <Col lg="5" md="6">
        <h1
          style={{
            fontSize: '33.3px',
          }}
          className="text-white text-center"
        >
          {t('auth:postSignupMessageHeader')}
        </h1>
        <PostSignupMessage />
      </Col>
    </>
  );
};

export default RegistrationSuccessfull;
