import axios from 'axios';
import 'lightbox2';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { DOMParser } from 'xmldom';

import FlowViewerLoader from './contentLoaders/FlowViewerLoader';
import FlowNotFound from './FlowNotFound';
import FlowViewerNavbar from './FlowViewerNavbar';
import Login from './Login';
import { flowService } from '../services/flow.service';

const FlowViewer = () => {
  const { t, ready } = useTranslation(['flows', 'global']);
  const token = localStorage.getItem('POR');
  const { id, identifier } = useParams();
  const [flow, setFlow] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [flowNotFound, setFlowNotFound] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [isTableOfContentsExist, setIsTableOfContentsExist] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const [redirectToFlowLibrary, setRedirectToFlowLibrary] = useState(false);
  const parser = new DOMParser();
  const divRef = useRef(null);

  console.log(window.location.href);
  useEffect(() => {
    const getFlow = async () => {
      try {
        const version = getVersionFromUrl();

        const response = await flowService.getFlow(id, identifier, version);
        console.timeLog(response);

        if (!response && !token) {
          //user is not logged in and flow is private
          setNotLoggedIn(true);
          setLoading(false);
        } else if (!response && token) {
          //user is logged in but with a different organization
          setRedirectToFlowLibrary(true);
          setNotLoggedIn(false);
          setLoading(false);
        } else {
          //user is logged in and is in the same organization or flow is public
          setFlow(response);
          if (response.url && response.type === 'static') {
            const urlObject = new URL(response.url);
            axios
              .get(urlObject.toString())
              .then((response) => {
                const parsedHtml = parser.parseFromString(
                  response.data,
                  'text/html'
                );

                const elementWithTocId = parsedHtml.getElementById('toc');
                setIsTableOfContentsExist(Boolean(elementWithTocId));

                setHtmlContent(response.data);

                setLoading(false);
              })
              .catch((error) => console.error(error));
          } else {
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
        setFlowNotFound(true);
      }
    };

    getFlow();
  }, []);

  useEffect(() => {
    const step = getStep();
    if (step) {
      if (divRef.current) {
        setTimeout(() => {
          navigateToObjectInViewer(step);
        }, 300);
      }
    }
  }, [divRef.current]);

  const getVersionFromUrl = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get('version');
  };

  const navigateToObjectInViewer = (objectId) => {
    const step = document.getElementById(objectId);
    if (step) {
      step.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getStep = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('step');
  };

  if (!ready) return null;
  return (
    <>
      {notLoggedIn && (
        <>
          <FlowViewerNavbar flowNotFound={true} />
          <Container>
            <div className="header-body text-center mt-6 mb-2 text-wrap">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <h1 className="text-black">
                    {t('flows:flowViewerMainHeading')}
                  </h1>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <h3 className="text-black">
                    {t('flows:flowViewerSecondHeading')}
                  </h3>
                </Col>
              </Row>
            </div>
            <Row className="justify-content-center">
              <Login mainLayout={false} redirectUrl={window.location.href} />
            </Row>
          </Container>
        </>
      )}
      {redirectToFlowLibrary && (
        <>
          <FlowViewerNavbar flowNotFound={true} />
          <Container>
            <div className="header-body text-center mt-6 mb-2 text-wrap">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <h1 className="text-black">
                    {t('flows:flowViewerRestrictedWarning')}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12">
                  <Button
                    color="primary"
                    className="float-center"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_FRONTEND_URL}/app/manage-flows`
                      )
                    }
                  >
                    {t('global:flowLibrary')}
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </>
      )}
      {flow?.type === 'static' && !flowNotFound && (
        <FlowViewerNavbar
          flowNotFound={false}
          status={flow.status}
          organizationId={flow.organizationId}
        />
      )}
      {!flowNotFound && flow?.type === 'static' && (
        <div
          ref={divRef}
          className={!isTableOfContentsExist ? 'static-flow-viewer' : ''}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      )}

      {!loading && flowNotFound && (
        <>
          <FlowViewerNavbar flowNotFound={true} />
          <FlowNotFound />
        </>
      )}

      {!flowNotFound && flow?.type === 'interactive' && (
        <div className={`full-screen-container`}>
          <Iframe
            url={flow?.url}
            width="100%"
            height="100%"
            overflow={'hidden'}
            display="block"
            position="relative"
          />
        </div>
      )}

      {loading && <FlowViewerLoader />}
    </>
  );
};

export default withTranslation(['global'])(FlowViewer);
