import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  Table,
  Tooltip,
  UncontrolledDropdown,
} from 'reactstrap';

import FlowLoader from './contentLoaders/FlowLoader';
import FlowUsageLoader from './contentLoaders/FlowUsageLoader';
import FlowVersionsModal from './FlowVersions';
import Header from './Header';
import ShareFlowModal from './ShareFlowModal';
import NotificationType from '../constants/notificationType';
import { flowService } from '../services/flow.service';
import { openNotification } from '../utlis/notification';
import { setFlowsCount } from 'redux/actions/flows.actions';

const ManageFlows = () => {
  const dispatch = useDispatch();
  const organizationMembership = useSelector(
    (state) => state.organizationMembership
  );
  const organization = useSelector((state) => state.organization);
  const { t, ready } = useTranslation(['global', 'flows', 'notifications']);

  const [flows, setFlows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [totalFlows, setTotalFlows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 8;
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedFlows, setSelectedFlows] = useState([]);
  const [currentFlow, setCurrentFlow] = useState(null);
  const [flowURL, setFlowURL] = useState('');
  const [flowStatus, setFlowStatus] = useState(false);
  const [openVersionsModal, setOpenVersionsModal] = useState(false);
  const [versionsList, setVersionsList] = useState([]);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await flowService.getAllFlows(
          currentPage,
          limit,
          searchText,
          sort
        );

        setFlows(response.flows);
        dispatch(setFlowsCount(response.total));
        setTotalFlows(response.total);
        setTotalPages(Math.ceil(response.total / response.limit));
        setLoading(false);
      } catch (e) {
        openNotification(e.error, NotificationType.ERROR);
        setLoading(false);
        setFlows([]);
      }
    };
    fetchFlows();
  }, [currentPage, searchText]);

  useEffect(() => {
    setFlows(
      [...flows].sort((a, b) => {
        if (a[sort] < b[sort]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sort] > b[sort]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      })
    );
  }, [sort, sortDirection]);

  useEffect(() => {
    if (currentFlow) {
      setFlowURL(currentFlow.viewUrl);
      setFlowStatus(currentFlow.status);
      toggle();
    }
  }, [currentFlow]);

  if (!ready) return null;
  const updateFlowStatus = (flowId, newStatus) => {
    setFlows(
      flows.map((flow) => {
        if (flow.id === Number(flowId)) {
          return { ...flow, status: newStatus };
        }
        return flow;
      })
    );
  };

  const handleSort = (column) => {
    if (sort === column) {
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSort(column);
      setSortDirection('asc');
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentFlow(null);
  };

  const toggleVersionsModal = () => {
    setOpenVersionsModal(!openVersionsModal);
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFlowSelect = (e, id) => {
    if (e.target.checked) {
      setSelectedFlows((prev) => [...prev, id]);
    } else {
      setSelectedFlows((prev) => prev.filter((flowId) => flowId !== id));
    }
  };

  const deleteFlow = async (e, id) => {
    e.preventDefault();

    try {
      await flowService.deleteFlow([id]);

      const filteredFlows = flows.filter((flow) => flow.id !== id);

      setFlows(filteredFlows);

      setTotalFlows(totalFlows - 1);
      dispatch(setFlowsCount(totalFlows - 1));
      openNotification(
        t('notifications:flowDeleteSuccess'),
        NotificationType.SUCCESS
      );

      if (selectedFlows.includes(id)) {
        setSelectedFlows((currentFlows) =>
          currentFlows.filter((flow) => flow !== id)
        );
      }
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  const handleGroupDelete = async () => {
    try {
      await flowService.deleteFlow(selectedFlows);
      openNotification(
        t('notifications:flowDeleteSuccess'),
        NotificationType.SUCCESS
      );
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }

    const filteredFlows = flows.filter(
      (flow) => !selectedFlows.includes(flow.id)
    );
    setFlows(filteredFlows);
    const newTotalFlows = totalFlows - selectedFlows.length;
    setTotalFlows(newTotalFlows);
    dispatch(setFlowsCount(newTotalFlows));
    setSelectedFlows([]);
  };

  const getVersionsList = async (e, id) => {
    e.preventDefault();

    try {
      const versionsList = await flowService.getFlowVersions(id);

      setVersionsList(versionsList);
      setOpenVersionsModal(true);
    } catch (e) {
      setOpenVersionsModal(false);
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  return (
    <>
      <Header activeLocation={'flowLibrary'} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="d-flex justify-content-between h3 bg-white border-0">
                <div>{t('flows:flows')}</div>

                <InputGroup
                  className="input-group-alternative"
                  style={{ maxWidth: '30%', margin: 'auto' }}
                >
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>

                  <Input
                    placeholder={t('global:search')}
                    type="text"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  {searchText && (
                    <Button onClick={clearSearchText}>
                      <i className="fas fa-times" />
                    </Button>
                  )}
                </InputGroup>

                <div
                  id="progressBarContainer"
                  onMouseEnter={toggleTooltip}
                  onMouseLeave={toggleTooltip}
                >
                  <div>
                    {organization?.flowsUploadLimit && totalFlows !== null ? (
                      <span>
                        {totalFlows}/{organization.flowsUploadLimit}
                      </span>
                    ) : (
                      <FlowUsageLoader />
                    )}
                  </div>

                  <Progress
                    value={totalFlows}
                    max={+organization.flowsUploadLimit}
                    style={{
                      height: '5px',
                    }}
                  />
                  <Tooltip
                    placement="left"
                    isOpen={tooltipOpen}
                    target="progressBarContainer"
                    style={{ backgroundColor: '#00284c' }}
                  >
                    <div>{t('flows:flowToolTipHeading')}</div>
                    <div>
                      <a
                        href="mailto:support@getflowshare.com"
                        style={{ color: 'white', textDecoration: 'underline' }}
                      >
                        <div>{t('flows:flowToolTipLink')}</div>
                      </a>
                    </div>
                  </Tooltip>
                </div>
              </CardHeader>
              <CardBody style={{ marginTop: '-2rem' }}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className={'col-1'} />
                      <th scope="col-4" onClick={() => handleSort('title')}>
                        <div style={{ cursor: 'pointer' }}>
                          {t('global:title')}
                          {sort === 'title' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th scope="col-2" onClick={() => handleSort('type')}>
                        <div style={{ cursor: 'pointer' }}>
                          {t('global:type')}
                          {sort === 'type' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th scope="col-1" onClick={() => handleSort('status')}>
                        <div style={{ cursor: 'pointer' }}>
                          {t('flows:status')}
                          {sort === 'status' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th
                        scope="col-1"
                        onClick={() => handleSort('numberOfSteps')}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          {t('flows:numberOfSteps')}
                          {sort === 'numberOfSteps' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th scope="col-2" onClick={() => handleSort('createdAt')}>
                        <div style={{ cursor: 'pointer' }}>
                          {t('global:date')}
                          {sort === 'createdAt' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th
                        scope="col-2"
                        onClick={() => handleSort('authorName')}
                      >
                        <div style={{ cursor: 'pointer' }}>
                          {t('global:author')}
                          {sort === 'author' &&
                            (sortDirection === 'asc' ? (
                              <i className="fas fa-sort-up" />
                            ) : (
                              <i className="fas fa-sort-down" />
                            ))}
                        </div>
                      </th>
                      <th scope="col-1" />
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <FlowLoader />
                      </tr>
                    ) : (
                      flows.length > 0 &&
                      flows.map((flow) => (
                        <tr key={flow.id}>
                          <td>
                            <input
                              disabled={
                                organizationMembership.roleName === 'chatter'
                              }
                              type="checkbox"
                              onChange={(e) => handleFlowSelect(e, flow.id)}
                            />
                          </td>
                          <td style={{ maxWidth: '300px' }}>
                            <div className="px-0">
                              <a
                                href={flow.viewUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: '#005883',
                                  display: 'inline-block',
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                                title={flow.title}
                              >
                                {flow.title}
                              </a>
                            </div>
                          </td>
                          <td>
                            {flow.type === 'interactive' && (
                              <Badge className="interactive">
                                {t(`global:${flow.type}`)}
                              </Badge>
                            )}
                            {flow.type === 'static' && (
                              <Badge className="static">
                                {t(`global:${flow.type}`)}
                              </Badge>
                            )}
                          </td>
                          <td>
                            {flow.status && (
                              <Badge className="public">
                                {t(`flows:public`)}
                              </Badge>
                            )}
                            {!flow.status && (
                              <Badge className="private">
                                {t(`flows:private`)}
                              </Badge>
                            )}
                          </td>
                          <td>{flow.numberOfSteps}</td>
                          <td>
                            {new Date(flow.createdAt).toLocaleString('en-us', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                          </td>
                          <td>{flow.authorName.toUpperCase()}</td>
                          <td
                            style={{
                              display: 'flex',
                              justifyItems: 'end',
                              justifyContent: 'end',
                              position: 'absolute',
                            }}
                          >
                            <UncontrolledDropdown
                              disabled={
                                organizationMembership.roleName === 'chatter'
                              }
                            >
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                color={''}
                              >
                                <i
                                  style={{
                                    color: '#00284c',
                                    fontSize: '1.3rem',
                                  }}
                                  className="fas fa-ellipsis-v"
                                />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                positionFixed={true}
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    setCurrentFlow(flow);
                                  }}
                                >
                                  {t('global:share')}
                                </DropdownItem>

                                <DropdownItem
                                  onClick={(e) => {
                                    deleteFlow(e, flow.id);
                                  }}
                                >
                                  {t('global:delete')}
                                </DropdownItem>

                                <DropdownItem
                                  onClick={(e) => {
                                    getVersionsList(e, flow.id);
                                  }}
                                >
                                  {t('global:versionNumber')}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className="py-4">
                <div className="d-flex justify-content-between align-items-center">
                  {selectedFlows.length !== 0 ? (
                    <Button
                      color={'danger'}
                      onClick={handleGroupDelete}
                      disabled={
                        selectedFlows.length === 0 ||
                        organizationMembership.roleName === 'chatter'
                      }
                    >
                      {t('global:delete')}
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={currentPage === 1 ? 'disabled' : ''}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePrevPage();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPages)].map((e, i) => (
                        <PaginationItem
                          className={currentPage === i + 1 ? 'active' : ''}
                          key={i}
                        >
                          <PaginationLink
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem
                        className={currentPage === totalPages ? 'disabled' : ''}
                      >
                        <PaginationLink
                          onClick={(e) => {
                            e.preventDefault();
                            handleNextPage();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <ShareFlowModal
          isOpen={isModalOpen}
          toggleModal={closeModal}
          url={flowURL}
          status={flowStatus}
          onUpdateFlowStatus={updateFlowStatus}
        />
        <FlowVersionsModal
          flowsMetaData={versionsList}
          isOpen={openVersionsModal}
          toggleModal={toggleVersionsModal}
        />
      </Container>
    </>
  );
};

export default ManageFlows;
