/* eslint-disable no-undef */
import { Buffer } from 'buffer';
import NOTIFICATION_TYPES from 'constants/notificationType';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from 'reactstrap';
import { setUser } from 'redux/actions/users.actions';
import { userService } from 'services/user.service';
import { openNotification } from 'utlis/notification';

import FirstLogin from './FirstLogin';
import { setJustLoggedIn } from '../redux/actions/misc.actions';
import { setOrganization } from '../redux/actions/organization.actions';
import { setOrganizationMembership } from '../redux/actions/organizationMembership.actions';
import { setFlowsCount } from 'redux/actions/flows.actions';
import AuthNavbar from './AuthNavbar';
import SSO from './sso/SSOMslaBrowserLogin';

const Login = ({ mainLayout, redirectUrl }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstLoginFirstName, setFirstLoginFirstName] = useState('');
  const [firstLoginLastName, setFirstLoginLastName] = useState('');

  useEffect(() => {
    if (password !== '' && email !== '') {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email, password]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      localStorage.clear();
      setLoading(true);

      const response = await userService.login(email, password);

      localStorage.setItem(
        'POR',
        Buffer.from(response.accessToken).toString('base64')
      );

      if (!response.lastLogin && mainLayout) {
        setFirstLoginLastName(response.lastName);
        setFirstLoginFirstName(response.firstName);
        setLoading(false);
        setIsModalOpen(true);
      }

      const userProfile = await userService.getUserProfile();

      dispatch(setUser(userProfile.user));

      dispatch(setOrganization(userProfile.organization));

      dispatch(setOrganizationMembership(userProfile.organizationMembership));

      dispatch(setFlowsCount(userProfile.flowsCount));

      dispatch(setJustLoggedIn(true));

      if (response.lastLogin) {
        //redirect incase component is called somewhere else
        mainLayout
          ? navigate(redirectUrl)
          : window.location.replace(redirectUrl);
      }
    } catch (error) {
      localStorage.clear();
      setLoading(false);
      setReadyToSend(false);

      openNotification(
        t(`notifications:${error.error}`),
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  return (
    <>
      {mainLayout && (
        <>
          <AuthNavbar />
          <Container>
            <div className="header-body text-center mb-2">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">{t('auth:authTitle')}</h1>
                </Col>
              </Row>
            </div>
          </Container>
        </>
      )}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {t('global:login')}
            </span>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('global:email')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                  <Input
                    placeholder={t('global:email')}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">
                  {t('global:password')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>

                  <Input
                    placeholder={t('global:password')}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    defaultValue={password}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={!readyToSend}
                  className="my-4"
                  type="submit"
                  color={'primary'}
                >
                  {t('global:signIn')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
            <div className="text-center">
              <SSO
                mainLayout={true}
                redirectUrl={redirectUrl}
                loginType={'login'}
              />
            </div>
          </CardBody>
        </Card>

        {mainLayout && (
          <Row className="mt-3">
            <Col xs="6">
              <Link
                as={'li'}
                style={{ color: 'white' }}
                to="/auth/password-forgot"
              >
                <small>{t('auth:signInForgotPasswordText')}</small>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link as={'li'} style={{ color: 'white' }} to="/auth/sign-up">
                <small>{t('auth:signUpText')}</small>
              </Link>
            </Col>
          </Row>
        )}
      </Col>
      <Modal isOpen={isModalOpen} size={'xl'} centered={true}>
        <ModalHeader>
          <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            {t('auth:firstLoginSetupAccountText')}
          </span>
        </ModalHeader>
        <ModalBody>
          <FirstLogin
            firstName={firstLoginFirstName}
            lastName={firstLoginLastName}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Login;
