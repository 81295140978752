import { NavbarBrand, Navbar } from 'reactstrap';

const SignUpNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <div className="nav-link-icon nav-link">
          <NavbarBrand>
            <img
              alt="logo"
              src={require('../../assets/img/brand/flowShare-logo-white.png')}
            />
          </NavbarBrand>
        </div>
      </Navbar>
    </>
  );
};

export default SignUpNavbar;
