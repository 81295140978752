import { useState } from 'react';
import SignUpBasic from './SignUpBasic';
import SignUpMain from './SignUpMain';
import SignUpNavbar from './SignUpNavbar';
import useGoogleTagManager from 'components/hook/GoogleTagManager';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import FeatureSignUpBasic from './FeaturesSignUpBasic';

const SignUpWrapper = () => {
  const [t, ready] = useTranslation(['auth']);

  const [showSignupMain, setShowSignUpMain] = useState(false);
  const [email, setEmail] = useState('');
  const [showPostSignUpMessage, setShowPostSignUpMesasge] = useState(false);

  useGoogleTagManager('GTM-KJ6CVXZ6');
  if (!ready) return null;
  const changeShowSignUp = (email) => {
    setEmail(email);
    setShowSignUpMain(true);
  };

  const changePostSignUpMessage = () => {
    setShowPostSignUpMesasge(!showPostSignUpMessage);
  };

  return (
    <>
      <SignUpNavbar />

      {showSignupMain && !showPostSignUpMessage && (
        <Col lg="5" md="6" sm="12" xs="12">
          <h1
            style={{
              fontSize: '33.3px',
              marginLeft: '-10%',
              whiteSpace: 'nowrap',
            }}
            className="text-white"
          >
            {t('auth:signUpMainHeading')}
          </h1>

          <SignUpMain
            email={email}
            changePostSignUpMessage={changePostSignUpMessage}
          />
        </Col>
      )}

      {!showSignupMain && !showPostSignUpMessage && (
        <Col lg="5" md="6" sm="12" xs="12">
          <FeatureSignUpBasic />
          <SignUpBasic changeShowSignUp={changeShowSignUp} />
        </Col>
      )}
    </>
  );
};

export default SignUpWrapper;
