import NOTIFICATION_TYPES from 'constants/notificationType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Button,
  Row,
  Label,
} from 'reactstrap';
import { organizationService } from 'services/organization.service';
import { openNotification } from 'utlis/notification';

import SSO from 'components/sso/SSOMslaBrowserLogin';
import Routes from 'routes/routes';
import PrivacyAndPolicy from './PrivacyAndPolicy';

const SignUpBasic = ({ changeShowSignUp }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const [email, setEmail] = useState('');

  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) &&
      acceptTerms
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email, acceptTerms]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    handleEmailSubmit();
    setLoading(false);
    setReadyToSend(false);
  };

  const getSource = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('source');
  };
  const handleEmailSubmit = async () => {
    try {
      await organizationService.addEmailInDirectory(email, getSource());
      changeShowSignUp(email);
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
    }
  };

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardBody className="sign-up-basic-card-body">
          <div className="mt-2">
            {/* TODO only initilize SSO when user clicks on the button */}
            <Form className="mt--2" onSubmit={handleSubmit}>
              <FormGroup className="mb-3 ">
                <Label className="font-weight-bold  label">
                  {t('auth:signUpBasicEmailLabel')}
                </Label>
                <InputGroup className="input-group-alternative ">
                  <Input
                    className="pl-2 input-field "
                    placeholder={'name@company.com'}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <PrivacyAndPolicy
                  changeValue={setAcceptTerms}
                  defaultValue={false}
                />
              </FormGroup>

              <div className="text-center">
                <Button
                  style={{ width: '100%' }}
                  disabled={!readyToSend}
                  type="submit"
                  color={'primary'}
                >
                  {t('auth:signUpBasicButton')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
          </div>
          <Row className="align-items-center my-3">
            <Col className="line-sign-in pr-0">
              <hr
                // style={{ borderTop: '2px solid black' }}
                className=" m-0 pr-0"
              />
            </Col>
            <Col className="span-sign-in">
              <span>{t('global:or')}</span>
            </Col>
            <Col className="line-sign-in pl-0">
              <hr
                // style={{ borderTop: '2px solid black' }}
                className=" m-0 pl-0"
              />
            </Col>
          </Row>
          <SSO
            mainLayout={true}
            redirectUrl={Routes.auth.ssoAccountLinked}
            loginType={'signup'}
          />
        </CardBody>
      </Card>
      <Row className="mt-1 text-center" style={{ color: 'white' }}>
        <Col className="col-12">
          <small>{`${t('signUpBasicLoginText')} `}</small>
          <small>
            <Link
              style={{ color: 'white' }}
              as={'li'}
              to={`/auth/login/`}
              className="text-underline"
            >
              {`${t('global:signIn')}.`}
            </Link>
          </small>
        </Col>
      </Row>
    </>
  );
};

export default SignUpBasic;
